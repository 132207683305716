<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>
          {{ IsAgreement ? "Convênios cadastrados" : "Vales cadastrados" }}
        </h4>
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="12" :sm="16" align="center">
              <el-row :gutter="4" type="flex" justify="end" align="center">
                <el-select
                  remote
                  value-key="uid"
                  :loading="!employees?.length"
                  @visible-change="fetchEmployees"
                  @change="selectEmployee"
                  v-model="employee"
                  no-data-text="Não existem logs para este usuário"
                  size="medium"
                >
                  <template #prefix>Funcionário:</template>
                  <el-option
                    v-for="item in employees"
                    :key="item.uid"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-row>
            </el-col>
            <el-button
              type="primary"
              icon="el-icon-plus"
              class="mt-5"
              :disabled="!employee"
              size="medium"
              @click="openDebtModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Debts"
      style="width: 100%; z-index: 0"
      v-if="employee"
    >
      <el-table-column
        label="descrição"
        :formatter="(r) => r?.obs?.slice(0, 15) || ''"
      >
      </el-table-column>
      <el-table-column
        label="realizado em"
        :formatter="(r) => formatDate(r?.realized_at)"
      >
      </el-table-column>
      <el-table-column
        label="valor"
        :formatter="(r) => formatCurrency(r?.amount)"
      >
      </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openDebtModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteDebt(cat.row)"
                title="
              Remover débito?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-row justify="center" v-else>
      <el-col :md="24">
        <h5>Selecione um funcionário para visualizar os débitos</h5>
      </el-col>
    </el-row>
    <employee-debt-modal
      :showModal="showEmployeeDebtModal"
      :employee="employee"
      :debt="debt"
      @close-modal="showEmployeeDebtModal = false"
      @should-update="fetchDebts"
    ></employee-debt-modal>
  </el-card>
</template>

<script>
import EmployeeDebtModal from "./modals/EmployeeDebtModal.vue";
import { ElNotification } from "element-plus";

export default {
  name: "EmployeeDebtPage",
  components: { EmployeeDebtModal },
  data: () => ({
    hasError: false,
    isLoading: false,
    employees: null,
    employee: null,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    debts: null,
    debt: null,
    showEmployeeDebtModal: false,
  }),
  watch: {
    "$route.params.type": function () {
      this.fetchDebts();
    },
  },
  computed: {
    IsAgreement() {
      return this.$route.params.type === "convenios";
    },
    Debts() {
      return (
        this.debts?.filter((d) => d.type === this.$route.params.type) || []
      );
    },
  },
  methods: {
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return new Date(c).toLocaleDateString();
      else return "-";
    },
    openDebtModal(debt) {
      this.debt = debt;
      this.showEmployeeDebtModal = true;
    },
    updateRole(role) {
      if (role)
        fetch(`${this.$store.state.apiUrl}roles/${role.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({ ...role }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.$emit("should-update");
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
              this.$emit("should-update");
              this.$emit("close-modal");
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar a função",
                message: e.message,
                position: "bottom-right",
              });
            }
          )
          .finally(() => {
            this.isLoadingSave = false;
            this.shouldShow = false;
          });
    },
    selectEmployee() {
      this.isLoading = true;
      this.fetchDebts();
    },
    fetchEmployees() {
      fetch(`${this.$store.state.apiUrl}employees`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.employees = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
    deleteDebt(debt) {
      if (debt)
        fetch(
          `${this.$store.state.apiUrl}employees/${this.employee.uid}/debts/${debt.uid}`,
          {
            credentials: "include",
            method: "DELETE",
          }
        )
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.json();
          })
          .then((e) =>
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            })
          )
          .catch((e) =>
            ElNotification.error({
              title: "Ocorreu um erro",
              message: e.message,
              position: "bottom-right",
            })
          )
          .finally(() => this.fetchDebts());
    },
    fetchDebts() {
      if (this.employee) {
        this.isLoading = true;
        fetch(
          `${this.$store.state.apiUrl}employees/${this.employee.uid}/debts`,
          {
            credentials: "include",
          }
        )
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.debts = json))
          .catch(() => (this.hasError = true))
          .finally(() => (this.isLoading = false));
      }
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
.mt-5 {
  margin-top: 10px !important;
  margin-left: 4px;
}
</style>