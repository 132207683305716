<template>
  <el-dialog v-model="shouldShow" :title="ModalTitle">
    <form>
      <el-row type="flex" justify="space-between" class="mb-1" v-if="!isNew">
        <h4 class="m-0">Estado do Débito:</h4>
        <el-radio-group v-model="debt_.status">
          <el-radio-button label="opened">Criado</el-radio-button>
          <el-radio-button label="paid">Pago</el-radio-button>
          <el-radio-button label="canceled">Cancelado</el-radio-button>
        </el-radio-group>
      </el-row>
      <el-row type="flex" justify="space-between">
        <h4 class="m-0">Funcionário:</h4>
        <small>{{ employee?.name }}</small>
      </el-row>
      <base-input label="Valor:" v-model="debt_.amount" type="money">
      </base-input>
      <base-input label="Observação:" v-model="debt_.obs"> </base-input>
      <el-row type="flex">
        <el-col :md="12"
          ><el-date-picker
            v-model="debt_.realized_at"
            type="date"
            size="medium"
            placeholder="Data realização:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker
        ></el-col>
        <el-col :md="12"
          ><el-date-picker
            v-model="debt_.expires_in"
            type="month"
            size="medium"
            placeholder="Mês vencimento.:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker
        ></el-col>
      </el-row>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";

export default {
  props: ["employee", "showModal", "debt"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      debt_: {},
      isLoadingSave: false,
    };
  },
  watch: {
    debt(v) {
      this.debt_ = v || {};
    },
  },
  computed: {
    ModalTitle() {
      return !this.isNew ? "Editar débito" : "Cadastrar débito";
    },
    IsAgreement() {
      return this.$route.params.type === "convenios";
    },
    isNew() {
      return !this.debt;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updateDebt() : this.createDebt();
    },
    createDebt() {
      if (this.employee)
        fetch(
          `${this.$store.state.apiUrl}employees/${this.employee.uid}/debts`,
          {
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
              ...this.debt_,
              status: "opened",
              type: this.$route.params.type,
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.$emit("should-update");
              this.shouldShow = false;
              this.debt_ = {};
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
              this.$emit("close-modal");
              this.$emit("should-update");
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível cadastrar o débito",
                message: e.message,
                position: "bottom-right",
              });
            }
          )
          .finally(() => {
            this.isLoadingSave = false;
          });
    },
    updateDebt() {
      if (this.employee)
        fetch(
          `${this.$store.state.apiUrl}employees/${this.employee.uid}/debts/${this.debt.uid}`,
          {
            credentials: "include",
            method: "PUT",
            body: JSON.stringify({ ...this.debt }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.$emit("should-update");
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
              this.$emit("should-update");
              this.$emit("close-modal");
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar o débito",
                message: e.message,
                position: "bottom-right",
              });
            }
          )
          .finally(() => {
            this.isLoadingSave = false;
            this.shouldShow = false;
          });
    },
  },
  name: "EmployeeDebtModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.checkbox-margin-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.text-muted {
  color: grey;
}
.m-0 {
  margin: 0px !important;
}
.mb-1 {
  margin-bottom: 4px;
}
</style>